import { Routes } from "@angular/router";

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "ta-kontroll-over-pensjonen-din",
};

export const actionsLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./pages/actions-page/actions-page.component").then((c) => c.ActionsPageComponent),
    data: {
      title: "pageTitle.actions",
      breadcrumb: {
        hideAll: true,
      },
    },
  },
];
